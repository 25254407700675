document.addEventListener('change', function (e) {
    const target = e.target.closest('.p-select');
    const searchParams = new URLSearchParams(window.location.search);

    if (target) {
        const filterName = target.getAttribute('id');
        const filterValue = target.value;
        if (filterValue) {
            searchParams.set(filterName, filterValue);
        } else {
            searchParams.delete(filterName);
        }
        // window.location.search = searchParams.toString();
        history.pushState(null, null, '?' + searchParams.toString());
    }
});