import request from 'oc-request';
import axios from 'axios';
export default new class Auth{

    constructor()
    {
        this.eventHandler();
    }

    eventHandler()
    {
        const _this = this;
        document.addEventListener('vue-mounted', function (v) {
            // document.addEventListener('readystatechange', function (e) {
            //     if (e.target.readyState === "complete") {
            let registerForm = document.querySelector('.register-form');
            let signInForm = document.querySelector('.sign-in-form');
            let updateForm = document.querySelector('.updateForm');

            if (registerForm) {
                _this.enableFields(registerForm);
                _this.checkMail(registerForm);
                _this.checkMinPasswdChar(registerForm);
            }
            if (signInForm) {
                _this.enableFields(signInForm);
                _this.signIn(signInForm);
            }
            if (updateForm) {
                _this.updateUser(updateForm);
            }
                // }
            // });
        });

        document.addEventListener('click', (e) => {
            let target = e.target.closest('.edit');
            if (target) {
                let password_fields = document.querySelectorAll('.updateForm input[name], .updateForm textarea[name]');
                password_fields.forEach((el) => {
                    el.removeAttribute('disabled');
                    el.setAttribute('placeholder', el.parentNode.querySelector('label').innerText);
                })
            }
        })

        document.addEventListener('click', (e) => {
            let target = e.target.closest('.deleteuser');
            let cancel = e.target.closest('.cancel-delete');
            if (target) {
                let formSelector = target.getAttribute('data-target');
                let form = document.querySelector(`#${formSelector}`);
                form.classList.remove('d-none');
                _this.deleteUser(form);
            }
            if (cancel) {
                let formSelector = cancel.getAttribute('data-target');
                let form = document.querySelector(`#${formSelector}`);
                form.classList.add('d-none');
            }
        })
    }

    checkMail(form)
    {
        const _this = this;
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'X-OCTOBER-REQUEST-HANDLER': 'onCheckEmail',
        }

        form.addEventListener('submit', async function (e) {
            e.preventDefault();
            e.stopPropagation();
            let mail = form.querySelector('[type=email]').value;
            form.classList.add('was-validated');
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
                _this.alertHandler('#errorRegister');
            }else {
                e.preventDefault();
                e.stopPropagation();
                _this.loaderHandler(form, '.loader-container');
                const response = await axios.post(
                    window.location.href,
                    {
                        email: mail
                    },
                    {
                        headers: headers,
                    }
                ).then((response) => {
                    return response.data;
                })

                if (response.data == false) {
                    _this.register(form);
                } else {
                    _this.alertHandler('#errorMailInUse')
                    _this.loaderHandler(form, '.loader-container');
                }
            }
        })
    }

    register(form)
    {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'X-OCTOBER-REQUEST-HANDLER': 'onRegister',
        }
        axios.post(
            window.location.href,
            new FormData(form),
            {
                headers: headers
            }
        ).then((response) => {
            const { status } = response;
            if (status == 200) {
                _this.alertHandler('#successRegister')
            }
        })
        .finally(()=>{
            new Promise(resolve => setTimeout(function (resolve, delay) {
            }, 4000))
            window.location.replace(window.location.href);
        })
    }

    signIn(form)
    {
        const _this = this;
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'X-OCTOBER-REQUEST-HANDLER': 'onSignIn',
        }
        form.addEventListener('submit', function (e) {
            e.preventDefault();
            e.stopPropagation();
            _this.loaderHandler(form, '.loader-container');
            axios.post(
                window.location.href,
                new FormData(form),
                {
                    headers: headers
                }
            ).then((response) => {
                const { status } = response;
                if (status == 200) {
                    request.sendData('onIsLoggedIn', {
                        success: function (data) {
                            if ( data.loggedIn ) {
                                _this.alertHandler('#successfullSignIn');
                            }
                        },
                        complete: function (data) {
                            window.location.replace('/');
                        }
                    });
                }
            })
            .catch((data)=>{
                _this.alertHandler('#errorSignIn')
                _this.loaderHandler(form, '.loader-container');
            })
            .finally(()=>{
            })
        })
    }

    updateUser(form)
    {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'X-OCTOBER-REQUEST-HANDLER': 'onUpdate',
        }
        form.addEventListener('submit', function (e) {
            e.preventDefault();
            e.stopPropagation();
            axios.post(
                window.location.href,
                new FormData(form),
                {
                    headers: headers
                }
            ).then((response) => {
                const { status } = response;
                if (status == 200) {
                    window.location.replace(window.location.href);
                }
            })
        })
    }

    deleteUser(form)
    {
        form.addEventListener('submit', function (e) {
            e.preventDefault();
            e.stopPropagation();
            request.sendForm(form, 'onDeactivate', {
                success: (res) => {
                    console.log(res)
                },
            });
        })
    }

    loaderHandler(form, selector)
    {
        let loader = form.querySelector(`${selector}`);
        if (loader.classList.contains('is-active')) {
            loader.classList.remove('is-active');
        }else {
            loader.classList.add('is-active');
        }
    }

    alertHandler(selector)
    {
        setTimeout(() => {
            let alert = document.querySelector(`${selector}`);
            alert.classList.add('show');
            setTimeout(() => {
                alert.classList.remove('show');
            }, 5000)
        }, 300)
    }

    enableFields(form)
    {
        let fields = form.querySelector('fieldset[disabled]');
        fields.removeAttribute('disabled')
    }

    checkCookie(name)
    {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) {
            return match[2];
        }
    }

    checkMinPasswdChar(registerForm){
        let input_passwd = registerForm.querySelector(`[name="password"]`)
        let alert = registerForm.querySelector(`.passwd-check`)
        input_passwd.addEventListener('keyup', (e) => {
            if(input_passwd.value.length < 8){
                if (alert.classList.contains('text-success')) {
                    alert.classList.remove('text-success');
                }
                alert.classList.add('text-danger');
                alert.innerText = alert.getAttribute('data-invalid')
            }else{
                if(input_passwd.value.length >= 8){
                    if (alert.classList.contains('text-danger')) {
                        alert.classList.remove('text-danger');
                    }
                    alert.classList.add('text-success');
                    alert.innerText = alert.getAttribute('data-valid')
                }
            }
        })
    }
}();
