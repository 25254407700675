import CartLinkHeader from '../cart-link-header/cart-link-header';
import Auth from '../auth/auth';
import request from 'oc-request';

export default new class ButtonAddToCart {
    constructor() {
        //Init selectors
        this.btnAddToCart = 'btn-add-to-cart';
        this.fastAddToCart = 'fast-add-to-cart';
        //Add events
        this.eventHandlers();
    }

    eventHandlers() {
        document.addEventListener('click', (e) => {
            if (e.target.closest(`.${this.btnAddToCart}`) && e.target.closest(`.${this.btnAddToCart}`).hasAttribute('disabled') == false){

                const   button = e.target.closest(`.${this.btnAddToCart}`),
                        event = button.getAttribute('data-event'),
                        form = button.parentNode.parentNode;

                button.setAttribute('disabled', true);

                if (event == 'add') {
                    this.addOfferToCart(form, button);
                } else {
                    if (event == 'remove') {
                        this.removeProductFromCart(form, button);
                    }
                }
            }
        });

        //fast add to cart
        document.addEventListener('click', (e) => {
            if (e.target.closest(`.${this.fastAddToCart}`) && e.target.closest(`.${this.fastAddToCart}`).hasAttribute('disabled') == false){

                const   button = e.target.closest(`.${this.fastAddToCart}`),
                        event = button.getAttribute('data-event'),
                        form = button.parentNode;

                button.setAttribute('disabled', true);

                if (event == 'add') {
                    this.addOfferToCart(form, button);
                } else {
                    if (event == 'remove') {
                        this.removeProductFromCart(form, button);
                    }
                }
            }
        });
    }

    addOfferToCart(form, button) {
        const _this = this
        const cartData = [], temp = [], property = [];
        var offerEmpty = false;
        const preloader = button.querySelectorAll(`div[data-loader="spinner"]`);
        if (preloader){
            // button.querySelector('p').classList.add('d-none');
            button.querySelectorAll('[data-loader="spinner"]').forEach( el => {
                el.classList.remove('d-none');
            })
        }

        form.querySelectorAll(`input[data-target='true']`).forEach((el, index) => {
            const name = el.getAttribute('name');
            if (!el.hasAttribute('data-type')) {
                if (el.value == '')
                    offerEmpty = true;
                temp.push([name, el.value])
            } else {
                property.push([name, el.value]);
            }
        })

        if (!offerEmpty) {
            let propertyObject = Object.fromEntries(property);
            temp.push(['property', propertyObject]);
            let object = Object.fromEntries(temp);
            cartData.push(object);
            button.setAttribute('data-event', 'remove');

            request.sendData('Cart::onAdd', {
                data: {'cart': cartData},
                success: (response) => {
                    if (!!response && response.status) {
                        CartLinkHeader.updateBlock();
                        let fastButton = document.querySelector(`.fast-add-to-cart[data-product-id="${ button.getAttribute('data-product-id') }"]`);
                        if (document.contains(fastButton) & fastButton != button ) {
                            fastButton.setAttribute('data-event', 'remove')
                            fastButton.querySelector('p').innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>`;
                        }else{
                            var text = button.getAttribute('data-remove-text')?button.getAttribute('data-remove-text'):'';
                            button.querySelector('p').innerHTML = `${text} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>`;
                        }
                        Auth.alertHandler('#itemAdded');
                        _this.updateThisBlock( button, preloader );
                    } else {
                        button.setAttribute('data-content', response.message);
                    }
                }
            });
        }else {
            Auth.alertHandler('#nonSizeSlected');
            preloader.classList.add('d-none');
            button.removeAttribute('disabled');
        }

    }

    removeProductFromCart(form, button) {
        const _this = this;
        const cartData = [];
        const temp = [];
        const property = [];
        let object = null;
        const preloader = button.querySelector(`div[data-loader="spinner"]`);

        if (preloader){
            // button.querySelector('p').classList.add('d-none');
            button.querySelectorAll('[data-loader="spinner"]').forEach( el => {
                el.classList.remove('d-none');
            })
            button.setAttribute('data-event', 'add');
        }

        form.querySelectorAll(`input[data-target='true']`).forEach((el, index) => {
            const name = el.getAttribute('name');
            if (!el.hasAttribute('data-type')) {
                temp.push([name, el.value])
            } else {
                property.push([name, el.value]);
            }
        })
        let propertyObject = Object.fromEntries(property);
        temp.push(['property', propertyObject]);
        object = Object.fromEntries(temp);
        cartData.push(object);

        request.sendData('Cart::onRemove', {
            data: {'cart': cartData},
            success: (response) => {
                if (!!response && response.status) {
                    CartLinkHeader.updateBlock();
                    let fastButton = document.querySelector(`.fast-add-to-cart[data-product-id="${ button.getAttribute('data-product-id') }"]`);
                        if (document.contains(fastButton) & fastButton != button ) {
                            fastButton.setAttribute('data-event', 'remove')
                            fastButton.querySelector('p').innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="10" cy="20.5" r="1"/><circle cx="18" cy="20.5" r="1"/><path d="M2.5 2.5h3l2.7 12.4a2 2 0 0 0 2 1.6h7.7a2 2 0 0 0 2-1.6l1.6-8.4H7.1"/></svg>`;
                        }else{
                            var text = button.getAttribute('data-add-text')?button.getAttribute('data-add-text'):'';
                            button.querySelector('p').innerHTML = `${text} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="10" cy="20.5" r="1"/><circle cx="18" cy="20.5" r="1"/><path d="M2.5 2.5h3l2.7 12.4a2 2 0 0 0 2 1.6h7.7a2 2 0 0 0 2-1.6l1.6-8.4H7.1"/></svg>`;
                        }
                    Auth.alertHandler('#itemRemoved');
                    _this.updateThisBlock( button, preloader );
                } else {
                    button.getAttribute('data-content', response.message);
                }
            }
        });
    }

    updateThisBlock(button, preloader) {
        const _this = this;
        let addto = document.querySelector(`.${_this.btnAddToCart}`);
        let fast = document.querySelector(`.${_this.fastAddToCart}`);
        if (addto)
            document.querySelector(`.${_this.btnAddToCart}`).removeAttribute('disabled');
            
        if (fast)
            document.querySelector(`.${_this.fastAddToCart}`).removeAttribute('disabled');
        return  button.querySelector('p').classList.add('d-block'),
                setTimeout(() => {
                    document.querySelectorAll('[data-loader="spinner"]').forEach( el => {
                        if (!el.classList.contains('d-none'))
                            el.classList.add('d-none');
                    })
                }, 1000), 
                button.removeAttribute('disabled');
    }
}();
