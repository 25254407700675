window.fadeOut = function fadeOut(el) {
    el.style.opacity = 1;

    var last = +new Date();
    var tick = function() {
        el.style.opacity = -el.style.opacity + (new Date() - last) / 400;
        last = -new Date();

        if (el.style.opacity > 0) {
            (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
        }else{
            if (el.style.opacity <= 0) {
                el.style.display = 'none'
            }
        }
    };

    tick();
}

window.fadeIn = function fadeIn(el) {
    el.style.opacity = 0;

    var last = +new Date();
    var tick = function() {
        el.style.opacity = +el.style.opacity + (new Date() - last) / 400;
        last = +new Date();

        if (el.style.opacity < 1) {
            (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
        }else{
            if (el.style.opacity >= 1) {
                el.style.opacity = 1
            }
        }
    };

    tick();
}

window.backHistory = function(index = null) {
    if(index) {
        window.history.go(index)
    }else{
        window.history.back();
    }
}

window.addUrlParameter = function(name, value, reload = true) {
    var searchParams = new URLSearchParams(location.search)
    searchParams.set(name, value)
    if (reload) {
        window.location.search = searchParams.toString();
    }else {
        window.history.pushState(null, null, `${location.origin}${location.pathname}?${searchParams.toString()}`)
    }
    document.dispatchEvent(new Event('url-modified'));
}

window.removeUrlParameter = function(name, reload = false) {
    var searchParams = new URLSearchParams(window.location.search);
    if(searchParams.has(name)){
        searchParams.delete(name)
        if(reload){
            location.search = searchParams.toString();
        }else{
            window.history.pushState({}, '', location.origin + location.pathname + '?' +searchParams.toString())
        }
    }
    document.dispatchEvent(new Event('url-modified'));
}

window.clearFilters = function(){
    var searchParams = new URLSearchParams(window.location.search);

    for(key in searchParams.keys()) {
        searchParams.delete(key);
    }
    location.replace(location.origin + location.pathname)
}
