import CatalogProductList from './../product-list/product-list';
import SearchResult from './../search-page/search-result'
// import PromoBlockProductList from './../promo-block-product-list/promo-block-product-list';
// import PromoBlockList from './../../promo-block/promo-block-list/promo-block-list';

export default new class ProductSorting {
    constructor() {
        this.sortingSelectSelector = 'sorting__select';

        this.catalogProductListLabel = 'catalog-product-list';
        this.promoBlockListLabel = 'promo-block-list';
        this.promoBlockProductListLabel = 'promo-block-product-list';
        this.searchListLabel = 'search-product-list';

        this.eventHandlers();
    }

    eventHandlers() {
        const _this = this;
        if (!document.querySelectorAll(`.${this.sortingSelectSelector} > option`).length) {
            return;
        }

        document.addEventListener('change', (e) => {
            const select = e.target.closest(`.${_this.sortingSelectSelector}`);

            if (select) {
                const label = select.getAttribute('aria-label');


                switch (label) {
                    case this.catalogProductListLabel:
                        CatalogProductList.updateProductList();
                        break;
                    case this.searchListLabel:
                        SearchResult.updateProductList();
                        break;

                    // case this.promoBlockListLabel:
                    //   PromoBlockList.updatePromoBlockList();
                    //   break;
                    // case this.promoBlockProductListLabel:
                    //   PromoBlockProductList.updateProductList();
                    //   break;
                }
            }
        });
    }
}();
